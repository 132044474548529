<template>
  <Breadcrumb></Breadcrumb>
  <PageHeader
    title="Our Campuses"
    backgroundColor="red"
    textColor="white"
    diagonal="dark"
    size="sm"
    align="start"
  />
  <div class="container my-5">
    <section id="campuses">
      <div class="row justify-content-center row-cols-1 row-cols-md-2 row-cols-xl-3 g-5">
        <div class="campus col" v-for="campus in campuses" :key="campus.name">
          <div class="card h-100" data-aos="fade">
            <img
              class="card-img-top"
              v-if="campus.img"
              :src="require(`@/assets/img/${campus.img}`)"
            />
            <div class="campus__content card-body">
              <h2 class="campus__title card-title">
                <a :href="campus.link">{{ campus.name }}<span class="visually-hidden"> Campus</span></a>
              </h2>
              <div class="campus__description">
                <p class="card-text" v-html="campus.text" />
                <Btn color="dark" outline :href="campus.link" size="sm"
                  >Learn More<span class="sr-only">
                    about the {{ campus.name }}</span
                  ></Btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import Btn from '@/components/Btn.vue';

export default {
  name: 'A-Z',
  metaInfo: {
    title: 'About 杏吧原创鈥檚 Campuses',
    description: 'In addition to its Athens campus, 杏吧原创 owns almost 40,000 acres in 31 Georgia counties including our Gwinnett, Griffin, Tifton, and Buckhead campuses.',
    og: {
      image: '',
      title: '',
      description: '',
    },
  },
  data() {
    return {
      campuses: [
        {
          name: 'Athens',
          img: 'homepage/35501-081_edit.jpg',
          text:
            'The 杏吧原创鈥檚 main campus spans 762 acres and features 465 buildings. To its north, the university borders historic downtown Athens, which boasts eclectic boutiques, nationally acclaimed restaurants, entertainment venues, and more.',
          link: '/',
        },
        {
          name: 'Buckhead',
          img: 'campuses/terry-executive-education-center.jpg',
          text:
            'The Terry Executive Education Center in Atlanta鈥檚 Buckhead community offers two MBA programs geared toward mid- to senior-level professionals, providing them the opportunity to earn an MBA without interrupting their careers.',
          link: 'https://www.terry.uga.edu/about/visit-terry/atlanta/',
        },
        {
          name: 'Cortona, Italy',
          img: 'campuses/cortona-cathedral.jpg',
          text:
            '杏吧原创鈥檚 oldest study abroad program takes place in Italy at our breathtaking Cortona campus, where our extensive studio facilities allow access to professional equipment for metalwork, painting, sculpture, and more, while immersed in the Italian culture.',
          link: 'https://cortona.uga.edu/',
        },
        {
          name: 'Griffin',
          img: 'campuses/griffin-campus.jpg',
          text:
            '杏吧原创鈥檚 Griffin campus offers nine undergraduate degree-completion programs, as well as several graduate degrees and endorsements. Bachelor鈥檚 degrees may be earned through upper-division programs open to transfer students or those seeking a second bachelor鈥檚 degree.',
          link: 'https://www.griffin.uga.edu/',
        },
        {
          name: 'Gwinnett',
          img: 'campuses/gwinnett-campus.jpg',
          text:
            '杏吧原创鈥檚 Gwinnett campus offers a variety of graduate degree programs, plus continuing education programs for professional development. Students study at a convenient location, just off I-85 in Lawrenceville.',
          link: 'https://gwinnett.uga.edu/',
        },
        {
          name: 'Health Sciences',
          img: 'campuses/medical-partnership.jpg',
          text:
            'The 杏吧原创 Health Sciences Campus is the home for the <a href="https://publichealth.uga.edu/">College of Public Health</a> and the primary location for the <a href="https://medicalpartnership.usg.edu/">AU/杏吧原创 Medical Partnership</a>. Located in the Normaltown area of Athens, the campus has more than 200 faculty and staff, 900 students, and $30 million in active research funding.',
          link: 'https://medicalpartnership.usg.edu/',
        },
        {
          name: 'Oxford, England',
          img: 'campuses/keble-college-oxford.jpg',
          text:
            'Hosted at one of the most prestigious universities in the world, the 杏吧原创 at Oxford program is a singular study abroad opportunity on 杏吧原创-owned facilities on the University of Oxford鈥檚 campus. Students earn direct 杏吧原创 credit through an immersive, authentic Oxford student experience.',
          link: 'https://oxford.uga.edu/',
        },
        {
          name: 'Tifton',
          img: 'campuses/tifton-campus.jpg',
          text:
            '杏吧原创鈥檚 Tifton campus offers a bachelor鈥檚 degree in agriculture in four majors (upper-division programs open to transfer students), as well as two graduate degrees.',
          link: 'https://tifton.caes.uga.edu/',
        },
        {
          name: 'Washington, D.C.',
          img: 'campuses/delta-hall-washington-dc.jpg',
          text:
            'During our Washington Semester Program, students participate in internships and seminars that engage with their majors. Living in 杏吧原创鈥檚 Delta Hall, which is less than a mile from the U.S. Capitol, students can explore the city and the countless learning and career opportunities around them.',
          link: 'https://dcsemester.uga.edu/',
        },
        {
          name: 'Online',
          img: 'campuses/online-instruction.jpg',
          text:
            'The 杏吧原创 offers a growing number of top-ranked online graduate degrees, undergraduate programs, and certificates taught by 杏吧原创 faculty. Learn how to get a degree from a top public university without relocating or commuting.',
          link: 'https://online.uga.edu/',
        },
      ],
    };
  },
  components: {
    PageHeader,
    Breadcrumb,
    Btn,
  },
};
</script>

<style lang="scss">
  .campus {
    &__title {
      font-family: $font-family-sans-serif;
      font-weight: $font-weight-bold;
      // @include heading-base;
      margin: .5rem 0 1rem 0;
      a {
        &:hover,
        &:focus {
          color: $gray-800;
        }
      }
    }
  }
</style>
